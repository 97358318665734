<template>
	<div class="container">
		<div class="query-title">
			<div class="demo-input-suffix" style="width: 100%;">
				<p class="titname">推广大厅管理</p>
				<!-- <el-button type="primary" class="tit-button" @click="addBtn">新增菜单</el-button> -->
			</div>
		</div>
		<div class="table-container">
			<el-table :data="newMenulist" :height="tableHeight" highlight-current-row border style="width: 100%;" row-key="id" :header-cell-style="{background:'rgba(113,183,255,0.4)',color:'#606266'}">
				<el-table-column type="index" align='center' width="55" label="序号"></el-table-column>
				<el-table-column prop="createTime" label="创建时间"></el-table-column>
        <el-table-column prop="name" label="名称"></el-table-column>
				<el-table-column prop="proportion" label="佣金比例(%)"></el-table-column>
				<el-table-column prop="" label="操作" width="90" align="center">
					<template slot-scope="scope">
						<el-button class="tabBnt" type="text" @click="edit(scope.row,scope.$index)">编辑</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<Dialog v-if="dialogVisible == true" :dialogdata="dialogData" @getcancel="Getcancel" @addetermine="addeteRmine"></Dialog>

	</div>
</template>

<script>
	import Dialog from './dialogPopup.vue';

	import {gdistributionlist} from "@/api/api";
	export default {
		name: 'typeofwork',
		components: {
			Dialog,
		},
		props: {},
		data() {
			return {
				dialogVisible: false,
				menuname: '',
				tableHeight: window.innerHeight - 188,
				newMenulist: [],
				dialogData: [],

			}
		},
		computed: {},
		watch: {},
		created() {
			window.addEventListener('resize', this.getHeight)
		},
		destroyed() {
			window.removeEventListener('resize', this.getHeight)
		},
		beforeDestroy() {},
		mounted() {

			this.menulist()
		},
		methods: {
			getHeight() {
				this.tableHeight = window.innerHeight - 188;
			},
			addBtn() {
				this.dialogVisible = true;
				this.dialogData = [];
			},
			edit(row, index) {
				this.dialogVisible = true;
				this.dialogData = [row, 'edit'];
			},


			/* deletes(row, index) {
				this.$confirm('此操作将永久删除, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(action => {
					if(action === 'confirm') {
						eTypeWorkdel({id:row.id}).then((res) => {

							if(res.status == 200) {
								this.menulist()
								this.$message({
									message: '删除成功',
									type: 'success'
								});
							} else {
								this.$message({
									message: res.message,
									type: 'success'
								});
							}
						}).catch((err) => {
							this.$message.error('删除失败！');

						})
					}
				}).catch(() => {

				});

			}, */


			addeteRmine() {
				this.dialogVisible = false;
				this.menulist()
			},
			Getcancel() {
				this.dialogVisible = false;
			},


			menulist() {
				this.newMenulist = []
        let params={
          pageIndex:1,
          pageSize:1000,

        }
				gdistributionlist().then((res) => {
					if(res.status == 200) {

            this.newMenulist = res.data;
					} else {
						this.$message.error(res.message);
					}

				}).catch((error) => {
					this.$message.error('获取菜单失败！');
				})

			},


		}
	}
</script>

<style scoped>

</style>
