<template>
  <div>
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="80px" class="demo-rulesFrom"
      style="height: 100%">
      <el-dialog v-dialogDrag title="佣金比例修改" :visible.sync="dialogVisible" :before-close="handleClose"
        :close-on-press-escape="false" :close-on-click-modal="false">
        <el-row>
          <el-col :span="12" class="rowTops">
            <el-form-item type="type" label="佣金比例" prop="proportion">
              <el-input :disabled="disableds" v-model.trim="ruleForm.proportion" placeholder="佣金比例" />
            </el-form-item>
          </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
          <el-button v-if="!disableds" type="primary" @click="deteRmine('ruleForm')">确 定</el-button>
          <el-button @click="cancel">关 闭</el-button>
        </span>
      </el-dialog>
    </el-form>
  </div>
</template>
<script>
  import {
    gdistributionsaveupdate
  } from "@/api/api";

  export default {
    name: "",
    components: {},
    props: {
      dialogdata: Array,
    },
    data() {
      return {
        dialogVisible: true,
        disableds: false,
        row: "",
        dialogState: "",
        ruleForm: {
          proportion: '',
        },
        rules: {

          proportion: [{
            required: true,
            message: "请输入菜单名称",
            trigger: "blur",
          }, ],

        },
        parentId: 0,
        parentlist: [],

        newMenulist: [],
      };
    },
    computed: {},
    watch: {},
    created() {},
    beforeDestroy() {},
    mounted() {

      if (this.dialogdata.length == 2) {
        this.row = this.dialogdata[0];
        this.dialogState = this.dialogdata[1];
        this.ruleForm.proportion = this.row.proportion;
      }
    },
    methods: {

      deteRmine(fromName) {
        this.$refs[fromName].validate((valid) => {
          if (valid) {
            if (this.dialogState != "edit") {

            } else {
              let params = {
               proportion:this.ruleForm.proportion,
                id: this.row.id
              };
              gdistributionsaveupdate(params).then((res) => {
                if (res.status == 200) {
                  this.$message({
                    message: "编辑成功！",
                    type: "success",
                  });
                  this.$emit("addetermine", {});
                } else {
                  this.$message.error(res.message);
                }
              }).catch((error) => {
                this.$message.error("编辑失败！");
              });
            }
          } else {
            return false;
          }
        });
      },
      cancel() {
        this.dialogVisible = false;
        this.$emit("getcancel");
      },

      handleClose() {
        this.dialogVisible = false;
        this.$emit("getcancel");
      },
    },
  };
</script>

<style scoped>
  .selectClass {
    width: 100%;
  }

  ::v-deep .el-dialog {
    width: 700px !important;
    border-radius: 8px;
    overflow: hidden;
  }

  ::v-deep .el-dialog__body {
    min-height: 100px;
    overflow: auto;
  }

  .rowTops {
    position: relative;
  }

  .rowTopa {
    position: absolute;
    left: 0px;
    top: 40px;
    right: 0px;
    margin: auto;
    max-height: 300px;
    background: #fff;
    border: 1px solid #e3e3e3;
    z-index: 100;
    border-radius: 5px;
    overflow: auto;
    padding-bottom: 10px;
  }

  .iptclose {
    position: absolute;
    right: 10px;
    top: 0px;
    color: #606266;
    font-size: 16px;
    line-height: 40px;
    z-index: 1000;
    transition: color 0.15s linear;
  }

  .iptclose:hover {
    color: #5cb6ff !important;
  }
</style>
